import { addDoc, doc, collection, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";

const languageToExtensionMap = {
  javascript: "js",
  typescript: "ts",
  python: "py",
  java: "java",
  csharp: "cs",
  cpp: "cpp",
  c: "c",
  plaintext: "txt",
};

export default function AddCodeFileModal({ pageData }) {
  const createFile = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const language = e.target.language.value;

    addDoc(collection(db, "code"), {
      filename: name + "." + languageToExtensionMap[language],
      language: language,
      content: "",
      edited: new Date(),
    }).then((res) => {
      updateDoc(doc(db, "pages", pageData.id), {
        code: [...pageData.code, res.id],
      }).then((res) => {
        e.target.reset();
        document.getElementById("create-file-modal").close();
      });
    });
  };

  return (
    <>
      <dialog id="create-file-modal" className="modal">
        <div className="modal-box">
          <form className="flex flex-col gap-3" onSubmit={createFile}>
            <h3 className="font-bold text-lg">Add a new file</h3>

            <div className="form-control">
              <label className="form-label" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="input input-bordered"
              />
            </div>

            <div className="form-control">
              <label className="form-label" htmlFor="language">
                Language
              </label>
              <select
                id="language"
                name="language"
                className="select select-bordered"
              >
                <option value="javascript">JavaScript</option>
                <option value="typescript">TypeScript</option>
                <option value="python">Python</option>
                <option value="java">Java</option>
                <option value="csharp">C#</option>
                <option value="cpp">C++</option>
                <option value="c">C</option>
                <option value="plaintext">Text</option>
              </select>
            </div>

            <button type="submit" className="btn btn-primary mt-8">
              Save
            </button>
          </form>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
