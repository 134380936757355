import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { auth } from "./config/firebase";
import OverviewPage from "./OverviewPage";
import SigninPage from "./SigninPage";
import CodePage from "./CodePage";
import { onAuthStateChanged } from "firebase/auth";
import { useState } from "react";

function App() {
  const [user, setUser] = useState(auth.currentUser);

  onAuthStateChanged(auth, (user) => {
    setUser(user);
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            user && user.uid === "aZhHqDwBJhhean8EJHsQWZqYwNg1" ? (
              <OverviewPage />
            ) : (
              <SigninPage />
            )
          }
        />
        <Route path="/:id" element={<CodePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
