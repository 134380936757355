// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAd9yWYBqMUkCMZIeRUWX5SrcW6Wy_wphk",
  authDomain: "codeshare-25401.firebaseapp.com",
  projectId: "codeshare-25401",
  storageBucket: "codeshare-25401.appspot.com",
  messagingSenderId: "491027483273",
  appId: "1:491027483273:web:f9ced5dc99e10a10801458",
  measurementId: "G-P4VYFEHW07",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
