import { db } from "./config/firebase";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import OverviewTable from "./components/OverviewTable";
import CreateUpdatePageModal from "./components/CreateUpdatePageModal";
import { useEffect, useState } from "react";

export default function OverviewPage() {
  const [data, setData] = useState([]);

  useEffect(() => {
    getDocs(
      query(collection(db, "pages"), orderBy("created", "desc"), limit(10))
    ).then((data) => {
      setData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
  }, []);

  return (
    <>
      <div className="bg-base-100 w-screen h-screen flex flex-col items-center md:p-24 pt-24 text-center sm:p-8">
        <h1 className="font-bold text-4xl text-base-400 mb-6">
          Welcome back, Robin
        </h1>
        <p>Start sharing code!</p>
        <div className="p-6 w-full m-auto mt-32">
          <div className="flex justify-between items-center mb-12">
            <button
              className="btn btn-primary"
              onClick={() => {
                document.getElementById("create-page-modal").showModal();
              }}
            >
              Add New
            </button>
            <input
              type="text"
              placeholder="Search..."
              className="input input-bordered w-full max-w-xs"
            />
          </div>

          <OverviewTable data={data} />
        </div>
      </div>
      <CreateUpdatePageModal />
    </>
  );
}
