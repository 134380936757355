import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "./config/firebase";

export default function SigninPage() {
  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  return (
    <div className="flex justify-center items-center h-screen flex-col">
      <h1 className="text-3xl font-bold mb-12">Continue to CODE</h1>
      <button onClick={loginWithGoogle} className="btn btn-primary">
        Login with Google
      </button>
    </div>
  );
}
