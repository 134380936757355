import { Editor } from "@monaco-editor/react";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { auth, db } from "./config/firebase";
import { useEffect, useRef, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { BsDownload } from "react-icons/bs";
import { RiSettingsLine } from "react-icons/ri";
import { IoAddCircleOutline } from "react-icons/io5";
import AddCodeFileModal from "./components/AddCodeFileModal";

export default function CodePage() {
  const [data, setData] = useState({});
  const [code, setCode] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [user, setUser] = useState(auth.currentUser);
  const { id } = useParams();

  const editorRef = useRef(null);

  onAuthStateChanged(auth, (user) => {
    setUser(user);
  });

  const isAdmin = user?.uid === "aZhHqDwBJhhean8EJHsQWZqYwNg1";

  useEffect(() => {
    getDoc(doc(db, "pages", id)).then((res) => {
      for (let i = 0; i < res.data().code.length; i++) {
        getDoc(doc(db, "code", res.data().code[i])).then((file) => {
          setCode((prev) => {
            if (file.id === prev.find((item) => item.id === file.id)?.id)
              return prev;
            const newState = [...prev];
            newState.push({ ...file.data(), id: file.id });
            return newState;
          });
        });
      }
      setData({ ...res.data(), id: res.id });
    });
  }, [id]);

  const saveCode = () => {
    const newCode = editorRef.current.getValue();
    if (newCode === code[selectedTab].content) return;
    updateDoc(doc(db, "code", code[selectedTab].id), {
      content: newCode,
    });

    setTimeout(() => {
      setCode((prev) => {
        const newState = [...prev];
        newState[selectedTab].content = newCode;
        return newState;
      });
    }, 0);
  };

  const downloadCode = () => {
    const blob = new Blob([editorRef.current.getValue()], {
      type: "text/plain",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = code[selectedTab].filename;
    a.click();
  };

  return (
    <div className="h-screen overflow-y-hidden bg-neutral-800">
      <div className="h-[130px] px-8 md:px-20 flex justify-between items-center">
        <div>
          <div className="flex gap-4 items-center">
            <h1 className="text-3xl font-semibold">{data.name}</h1>
            <BsDownload className="cursor-pointer" onClick={downloadCode} />
            {isAdmin && <RiSettingsLine />}
          </div>
          <p>{data.description}</p>
        </div>
        {(data.editable || isAdmin) && (
          <button className="btn btn-outline" onClick={saveCode}>
            Save changes
          </button>
        )}
      </div>

      <div role="tablist" className="tabs tabs-lifted w-10">
        {code.length > 0 &&
          code.map((file, index) => (
            <button
              key={file.id}
              role="tab"
              className={index === selectedTab ? "tab tab-active" : "tab"}
              onClick={() => setTimeout(() => setSelectedTab(index), 0)}
            >
              {file.filename}
            </button>
          ))}
        <div
          className="px-4 cursor-pointer tab"
          onClick={() =>
            document.getElementById("create-file-modal").showModal()
          }
        >
          <IoAddCircleOutline size={25} />
        </div>
      </div>

      <Editor
        onMount={(editor) => (editorRef.current = editor)}
        height="90vh"
        value={code.length > selectedTab && code[selectedTab].content}
        language={
          code.length > selectedTab ? code[selectedTab].language : "plaintext"
        }
        theme="vs-dark"
        options={{
          minimap: { enabled: false },
          padding: { top: 10 },
        }}
      />

      <AddCodeFileModal pageData={data} />
    </div>
  );
}
