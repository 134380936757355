import { BsThreeDots } from "react-icons/bs";
import { FaLock, FaLockOpen } from "react-icons/fa";
import SecurityModal from "./SecurityModal";
import { useNavigate } from "react-router-dom";

export default function OverviewTable({ data }) {
  const router = useNavigate();

  return (
    <div className="overflow-x-auto">
      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Date Created</th>
            <th>Last Modified</th>
            <th>Files</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr
              className="hover cursor-pointer"
              onClick={() => router(`/${item.id}`)}
            >
              <td
                onClick={() => {
                  document.getElementById("security-modal").showModal();
                }}
              >
                {item.protected ? <FaLock /> : <FaLockOpen />}
              </td>
              <td>{item.name}</td>
              <td>{item.created.toDate().toLocaleDateString()}</td>
              <td>{item.updated.toDate().toLocaleDateString()}</td>
              <td>{item.code.length}</td>
              <th>
                <BsThreeDots />
              </th>
            </tr>
          ))}
        </tbody>
      </table>
      <SecurityModal data={data} />
    </div>
  );
}
