import { addDoc, collection } from "firebase/firestore";
import { db } from "../config/firebase";
import { useNavigate } from "react-router-dom";

export default function CreateUpdatePageModal() {
  const router = useNavigate();

  const createPage = (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const description = e.target.description.value;
    const editable = e.target.editable.checked;
    const protect = e.target.protected.checked;
    const password = e.target.password.value;

    addDoc(collection(db, "pages"), {
      name,
      description,
      editable,
      protected: protect,
      password,
      created: new Date(),
      updated: new Date(),
      code: [],
    }).then((res) => {
      e.target.reset();
      document.getElementById("create-page-modal").close();
      router(`/${res.id}`);
    });
  };

  return (
    <>
      <dialog id="create-page-modal" className="modal">
        <div className="modal-box">
          <form className="flex flex-col gap-3" onSubmit={createPage}>
            <h3 className="font-bold text-lg">Create a new page</h3>

            <div className="form-control">
              <label className="form-label" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="input input-bordered"
              />
            </div>

            <div className="form-control">
              <label className="form-label" htmlFor="description">
                Description
              </label>
              <textarea
                id="description"
                name="description"
                className="textarea textarea-bordered"
              ></textarea>
            </div>

            <div className="flex gap-24">
              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text">Editable</span>
                </label>
                <input
                  name="editable"
                  type="checkbox"
                  className="toggle"
                  defaultChecked
                />
              </div>

              <div className="form-control">
                <label className="label cursor-pointer">
                  <span className="label-text">Protected</span>
                </label>
                <input name="protected" type="checkbox" className="toggle" />
              </div>
            </div>

            <div className="form-control">
              <label className="form-label" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                name="password"
                className="input input-bordered"
              />
            </div>

            <button type="submit" className="btn btn-primary mt-8">
              Save
            </button>
          </form>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
